import Axios from "axios";
import {useEffect} from "react";

export const httpClientCRM = Axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: process.env.REACT_APP_TIMEOUT,
});

function HttpClientManagement(props) {
    const setHeaders = (httpClientAxios) => {
        httpClientAxios.headers["Accept"] = "application/json";
        return httpClientAxios;
    };

    const printToConsoleError = (message, error) => {
        console.error(`error HttpClientManagement message: ${message}`);
        console.error(
            `error HttpClientManagement url: ${error.config.method?.toUpperCase()} ${
                error.config.baseURL
            }${error.config.url}`
        );
        console.error(
            `error HttpClientManagement detail: ${JSON.stringify(error)}`
        );
        if (error.response && error.response.data) {
            const responseData = error.response.data;
            console.error(
                `error HttpClientManagement code: ${responseData.code} | error: ${responseData.error} | message: ${responseData.message}`
            );
        }
    };

    const httpResponseHandling = (response) => {
        if (!response || !response.data) return null;
        return response.data;
    };

    const httpErrorHandling = (error) => {
        let message;
        if (error.message === "Network Error") {
            message = "Network Error";
            printToConsoleError(message, error);
            throw message;
        }

        if (error.code === "ECONNABORTED") {
            message = "ECONNABORTED";
            printToConsoleError(message, error);
            throw message;
        }

        if (error.response && error.response.status === 400) {
            message = `Bad Requests`;
            printToConsoleError(message, error);
            throw message;
        }

        if (error.response && error.response.status === 401) {
            message = "Unauthorized";
            printToConsoleError(message, error);
            throw message;
        }

        if (error.response && error.response.status === 404) {
            message = "Not Found";
            printToConsoleError(message, error);
            throw message;
        }

        if (error.response && error.response.status === 422) {
            message = "Unprocessable Entity";
            printToConsoleError(message, error);
            throw message;
        }

        throw error;
    };

    useEffect(() => {
        httpClientCRM.interceptors.request.use((config) => setHeaders(config));
        httpClientCRM.interceptors.response.use((res) => httpResponseHandling(res), (error) => httpErrorHandling(error));
    }, []);

    return props.children;
}

export default HttpClientManagement;
