import "./style.scss";
import React from "react";
import iconSearch from "../../icons/Btn-Search.svg";
import { useNavigate } from "react-router-dom";
export default function SearchNav() {
  const history = useNavigate();

  function handleChange(e) {
    if (e.target.value !== '') {
      history(`/?search=${e.target.value}&page=0`, {state: {
        value: e.target.value,
        isSearch: true,
      }});
    }else{
      history(`/?search=`);
    }
  }
  return (
    <div className="content-search">
      <input type="text" name="search" placeholder="Busca por cód. Producto o Ref." id="search" onChange={(e) => handleChange(e)} className="input-search"/>
      <img src={iconSearch} alt="buscar" className="icon-search" />
    </div>
  );
}
