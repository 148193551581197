import "./style.scss";
import React from "react";
import Logo from "../../img/Grupo28.svg";
import SearchNav from '../nav-search/index';
import { Link } from "react-router-dom";

export default function HeaderComponent() {
  return (
    <>
      <div className="content-main-header">
        <Link to={"/"}>
          <img
            src={Logo}
            alt="logo laumayer"
            className="logo"
            width={100}
            height={50}
          />
        </Link>

        <div className="cont-search-flex">
          <SearchNav></SearchNav>
        </div>
      </div>
    </>
  );
}
