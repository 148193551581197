import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import Router from "./router/router";
import { BrowserRouter, } from "react-router-dom";
import HttpClientManagement from "./API/httpClientManagement";
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";


ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <HttpClientManagement>
                <Router />
            </HttpClientManagement>
        </BrowserRouter>
        <ToastContainer position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover />
    </React.StrictMode>,
    document.getElementById('root')
);
