import React from "react";
import "./styles.scss";
import HeaderComponent from "../../components/header-component";
import InventoryComponent from "../../components/inventory-component";
import BannerComponent from "../../components/banner-component";
export default function InventoryContainer() {
 
  return (
    <div className="container-main-inventory">
      <HeaderComponent ></HeaderComponent>
      <BannerComponent></BannerComponent>
      <InventoryComponent></InventoryComponent>
    </div>
  );
}
