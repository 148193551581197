import LayoutComponent from '../containers/layout/index'
import {Route, Routes} from "react-router-dom";
import {useEffect} from "react";

import { toast } from "react-toastify";
export default function Router() {

    const TOKEN = localStorage.getItem('SESION_ID');

    return (
        <Routes>
            <Route index path="/" element={<LayoutComponent></LayoutComponent>}/>
        </Routes>
    );
}
