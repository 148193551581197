import "./style.scss";
import React from "react";
import Bannerlef from "../../img/banner.png";
import fondo from "../../img/fondo.svg";
import fondo2 from "../../img/fondo-two.svg";


export default function BannerComponent() {

  return (
    <div className="container-main-body">
      <div className="banner-static">
        <img src={Bannerlef} alt="" className="image-left-banner" />
        <div className="background-banner">
          <img src={fondo} alt="" className="position-absolut" />
          <img src={fondo2} alt="" className="position-absolut-bot" />
          <div className="content-title-banner">
            <h2>Encuentra aquí nuestra</h2>
            <h1>LISTA DE PRECIOS</h1>
            <div>
              <h4>2022</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
