import React, {useEffect, useState} from "react";
import "./style.scss";
import top from "./../../icons/top-arrow.svg";
import closed from "../../icons/closed.svg";
import Vermas from "../../icons/verMas.svg";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {httpClientCRM} from "../../API/httpClientManagement";
import {toast} from "react-toastify";
import Pagination from "@mui/material/Pagination";

export default function InventoryComponent() {

    const bodyLogin = new URLSearchParams();
    const TOKEN = "SESION_ID";
    // const [brands, setBrands] = useState([]);
    const session_id = localStorage.getItem("SESION_ID");
    const bodyBrand = new URLSearchParams();
    const [value, setValue] = useState(1);
    const history = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const [producDetail, setProducDetail] = useState({});
    const [countPage, setCountPage] = useState(0);
    const [products, setProducts] = useState([]);
    const [inventory, setInvetory] = useState({});
    const [search, setSearch] = useState(searchParams.get("search"));
    const [pageSearch, setPageSearch] = useState(searchParams.get("page"));
    const [ordered, setOrdered] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isAsc, setIsAsc] = useState("");
    const [codigo_cisAsc, setCodigo_cisAsc] = useState(true);
    const [nameisAsc, setNameisAsc] = useState(true);
    const [referencia_cisAsc, setReferencia_cisAsc] = useState(true);
    const [cantidad_cisAsc, setCantidad_cisAsc] = useState(true);
    const [precio1_cisAsc, setPrecio1_cisAsc] = useState(true);

    const user = process.env.REACT_APP_API_USER
    const password = process.env.REACT_APP_API_PASSWORD

    function getInventory(search, page, orderby) {

        const request = {
            session: `${session_id}`,
            module_name: "psg_Productos",
            query:
                !search || search == "null"
                    ? ""
                    : `psg_productos.name like '%${search}%' or psg_productos_cstm.codigo_c like '%${search}%'  or psg_productos_cstm.referencia_c like '%${search}%'`,
            order_by: orderby ? orderby : "",
            offset: page ? page : 0,
            select_fields: [
                "id",
                "name",
                "cantidad_c",
                "codigo_c",
                "marca_c",
                "precio1_c",
                "referencia_c",
                "grupo_c",
            ],
        };

        bodyBrand.append("rest_data", JSON.stringify(request));
        bodyBrand.append("method", "get_entry_list");
        bodyBrand.append("response_type", "json");
        bodyBrand.append("input_type", "json");

        httpClientCRM
            .post("service/v4_1/rest.php", bodyBrand)
            .then((res) => {
                if (res && res.data) {
                    setInvetory(res.data);
                    let totalItemsPagination = res.data.total_count / 20;
                    setCountPage(Math.ceil(totalItemsPagination || 0));
                    setProducts(res.data.entry_list);

                } else {

                    setInvetory(res);
                    let totalItemsPagination = res.total_count / 20;
                    setCountPage(Math.ceil(totalItemsPagination));
                    setProducts(res.entry_list);
                }
            })
            .catch((error) => {
                toast.error("error obteniendo el inventario de la marca", {
                    theme: "colored",
                });
            });
    }

    function orderProducts(e) {
        switch (e.target.id) {
            case "codigo_c":
                if (codigo_cisAsc) {
                    const query = e.target.id + " desc";
                    getInventory(search, pageSearch, query);
                    setIsAsc(`${query}`);
                    setCodigo_cisAsc(false);
                    setOrdered(true);
                } else {
                    const query = e.target.id + " asc";
                    getInventory(search, pageSearch, query);
                    setIsAsc(`${query}`);
                    setCodigo_cisAsc(true);
                }
                break;
            case "name":
                if (nameisAsc) {
                    const query = e.target.id + " desc";
                    getInventory(search, pageSearch, query);
                    setIsAsc(`${query}`);
                    setNameisAsc(false);
                    setOrdered(true);
                } else {
                    const query = e.target.id + " asc";

                    getInventory(search, pageSearch, query);
                    setIsAsc(`${query}`);
                    setNameisAsc(true);
                }
                break;

            case "referencia_c":
                if (referencia_cisAsc) {
                    const query = e.target.id + " desc";
                    getInventory(search, pageSearch, query);
                    setIsAsc(`${query}`);
                    setReferencia_cisAsc(false);
                    setOrdered(true);
                } else {
                    const query = e.target.id + " asc";
                    getInventory(search, pageSearch, query);
                    setIsAsc(`${query}`);
                    setReferencia_cisAsc(true);
                }
                break;
            case "cantidad_c":
                if (cantidad_cisAsc) {
                    const query = e.target.id + " desc";
                    getInventory(search, pageSearch, query);
                    setIsAsc(`${query}`);
                    setCantidad_cisAsc(false);
                    setOrdered(true);
                } else {
                    const query = e.target.id + " asc";
                    getInventory(search, pageSearch, query);
                    setIsAsc(`${query}`);
                    setCantidad_cisAsc(true);
                }
                break;
            case "precio1_c":
                if (precio1_cisAsc) {
                    const query = e.target.id + " desc";
                    getInventory(search, pageSearch, query);
                    setIsAsc(`${query}`);
                    setPrecio1_cisAsc(false);
                    setOrdered(true);
                } else {
                    const query = e.target.id + " asc";
                    getInventory(search, pageSearch, query);
                    setIsAsc(`${query}`);
                    setPrecio1_cisAsc(true);
                }
                break;
            default:
                break;
        }
    }

    function showModalProduct(i) {
        setProducDetail(products[i].name_value_list);
        setShowModal(true);
    }

    function closedModal() {
        setProducDetail({});
        setShowModal(false);
    }

    function handlePage(event, page) {
        setValue(page);
        let convert = page - 1;
        if (convert !== 0) {
            let result = convert * 20;
            history(`/?search=${search}&page=${result}`);
        } else {
            history(`/?search=${search}&page=${convert}`);
        }
    }

    async function login() {
        const request = {
            user_auth: {
                user_name: user,
                password: password,
            },
            application_name: "Integración Laumayer",
            name_value_list: {},
        };

        bodyLogin.append("rest_data", JSON.stringify(request));
        bodyLogin.append("method", "login");
        bodyLogin.append("response_type", "json");
        bodyLogin.append("input_type", "json");

        httpClientCRM
            .post("service/v4_1/rest.php", bodyLogin)
            .then((response) => {
                if (response) {
                    if (response.data) {
                        setToken(response.data.id);
                        getInventory();
                    } else {
                        setToken(response.id);
                        getInventory();
                    }
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error("error obteniendo el token", {
                    theme: "colored",
                });
            });
    }
    function filterOrdened() {
        switch (isAsc) {
            case "codigo_c desc":

                let querycoddes = "codigo_c desc";
                getInventory(search, pageSearch, querycoddes);

                break;
            case "codigo_c asc":
                let querycodas = "codigo_c asc";
                getInventory(search, pageSearch, querycodas);
                break;

            case "cantidad_c desc":
                let querycant = "cantidad_c desc";
                getInventory(search, pageSearch, querycant);
                break;
            case "cantidad_c asc":
                let queryas = "cantidad_c asc";
                getInventory(search, pageSearch, queryas);

                break;
            case "precio1_c desc":
                let querypri = "precio1_c desc";
                getInventory(search, pageSearch, querypri);
                break;

            case "precio1_c asc":
                let queryprias = "precio1_c asc";
                getInventory(search, pageSearch, queryprias);

                break;

            case "referencia_c desc":
                let queryref = "referencia_c desc";
                getInventory(search, pageSearch, queryref);
                break;

            case "referencia_c asc":
                let queryrefas = "referencia_c asc";
                getInventory(search, pageSearch, queryrefas);

                break;

            case "name desc":
                let queryname = "name desc";
                getInventory(search, pageSearch, queryname);
                break;

            case "name asc":
                let querynameas = "name asc";
                getInventory(search, pageSearch, querynameas);

                break;
            default:
                break;
        }
    }

    function setToken(token) {
        if (!token) return;
        localStorage.setItem(TOKEN, token);
    }


    useEffect(() => {
        login();
    }, []);


    useEffect(() => {
        if (!session_id) {
            return;
        }
        setSearch(searchParams.get("search"));
        if (searchParams.get("page") === '0') {
            setPageSearch(searchParams.get("page"));
            setValue(1)
        }
    }, [searchParams]);

    useEffect(() => {
        if (!session_id) {
            return;
        }
        if (ordered) {
            filterOrdened();
        } else {
            getInventory(search, pageSearch);
        }
        // getInventory(search, pageSearch);
    }, [session_id, search, pageSearch]);
    return (
        <>
            <div className="container-main-inventory-component">
                <div className="container-list-inventory">
                    <div className="top-list-inventory">
                        <div className="column-filter-1">
                            <button
                                type="button"
                                id="codigo_c"
                                onClick={(e) => orderProducts(e)}
                            ></button>
                            <h2>Código</h2>
                            <img
                                src={top}
                                alt=""
                                className={codigo_cisAsc ? "transition" : "rotation-asc"}
                                width={15}
                            />
                        </div>
                        <div className="column-filter-2">
                            <button
                                type="button"
                                id="name"
                                onClick={(e) => orderProducts(e)}
                            ></button>
                            <h2>Producto</h2>
                            <img
                                src={top}
                                alt=""
                                className={nameisAsc ? "transition" : "rotation-asc"}
                                width={15}
                            />
                        </div>
                        <div className="column-filter-4">
                            <button
                                type="button"
                                id="referencia_c"
                                onClick={(e) => orderProducts(e)}
                            ></button>
                            <h2>Referencia</h2>
                            <img
                                src={top}
                                alt=""
                                className={referencia_cisAsc ? "transition" : "rotation-asc"}
                                width={15}
                            />
                        </div>
                        {/* <div className="column-filter-5">
                            <button
                                type="button"
                                id="cantidad_c"
                                onClick={(e) => orderProducts(e)}
                            ></button>
                            <h2>Und. empaque</h2>
                            <img
                                src={top}
                                alt=""
                                className={cantidad_cisAsc ? "transition" : "rotation-asc"}
                                width={15}
                            />
                        </div> */}
                        <div className="column-filter-6">
                            <button
                                type="button"
                                id="precio1_c"
                                onClick={(e) => orderProducts(e)}
                            ></button>
                            <h2>Precio</h2>
                            <img
                                src={top}
                                alt=""
                                className={precio1_cisAsc ? "transition" : "rotation-asc"}
                                width={15}
                            />
                        </div>
                        <div className="column-filter-7-mobile">
                            <h2>Ver más</h2>
                            <button type="button">
                                <img src={top} alt="" width={15}/>
                            </button>
                        </div>
                    </div>
                    <div className="cont-results">
                        {
                        
                        !products || products?.length < 1 ? <div className="not-found-products">No se encontró nombre, código o referencia asociados a su búsqueda</div> :
                        
                        
                        products?.map((product, index) => (
                            <div key={index} className="list-inventory-result">
                                <div className="column-result-1">
                                    <h2>{product?.name_value_list?.codigo_c.value}</h2>
                                </div>
                                <div className="column-result-2">
                                    <h2>{product?.name_value_list?.name.value}</h2>
                                </div>
                                <div className="column-result-4">
                                    <h2>{product?.name_value_list?.referencia_c.value}</h2>
                                </div>
                                {/* <div className="column-result-5">
                                    <h2>{product?.name_value_list?.cantidad_c.value}</h2>
                                </div> */}
                                <div className="column-result-6">
                                    <p>$</p>
                                    <h2>{product?.name_value_list?.precio1_c.value}</h2>
                                </div>
                                <div className="column-result-7-mobile">
                                    <button
                                        type="button"
                                        id=""
                                        onClick={() => showModalProduct(index)}
                                    >
                                        <img src={Vermas} alt=""/>
                                    </button>
                                </div>
                            </div>
                        ))}
                        
                    </div>
                    <hr/>
                    <div className="cont-paginator">
                        <div className="cont-left-paginator">
                            <div className="column-1-register-per-page">
                                <p>Registros por página:</p>
                                <span>{inventory.result_count}</span>
                            </div>
                            <div className="column-2-result-paginator">
                                <p>Total de resultados:</p>
                                <span> {inventory.total_count}</span>
                            </div>
                        </div>

                        <div className="column-3-paginator">
                            <Pagination
                                count={parseInt(`${countPage}`)}
                                page={value}
                                value={"hola"}
                                onChange={(event, page) => handlePage(event, page)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {showModal && (
                <div className="cont-main-modal">
                    <div className="modal-card">
                        <div className="row-1-closed">
                            <img
                                src={closed}
                                width={20}
                                height={20}
                                onClick={() => closedModal()}
                                alt=""
                            />
                        </div>
                        <div className="row-2-product">
                            <div className="cont-name-product">
                                <h4>Producto</h4>
                                <h2>{producDetail.name?.value}</h2>
                            </div>
                            <div className="cont-data-info">
                                <div className="data-1">
                                    <h4>Código</h4>
                                    <h2>{producDetail.codigo_c?.value}</h2>
                                </div>
                                <div className="data-2">
                                    <h4>Referencia</h4>
                                    <h2>{producDetail.referencia_c?.value}</h2>
                                </div>
                                {/* <div className="data-3">
                                    <h4>Und. empaque</h4>
                                    <h2>{producDetail.cantidad_c?.value}</h2>
                                </div> */}
                                <div className="data-4">
                                    <h4>Precio</h4>
                                    <h2>{producDetail.precio1_c?.value}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row-3-return">
                            <button type="button" onClick={() => closedModal()}>
                                Volver al listado
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
